<template>
  <div>
    <div style="font-size: 36px">{{ $t('tools.a2') }}</div>

    <el-table
      :data="tableData"
      class="table mar30"
      border
      style="width: 100%"
    >
      <el-table-column prop="menu" label="Menu">
        <template slot-scope="scope">
          {{$t(scope.row.menu)}}
        </template>
      </el-table-column>
      <el-table-column :label="$t('tools.a2')">
			<el-table-column prop="mainteneur" label="mainteneur" width="120" align="center"></el-table-column>
			<el-table-column prop="config" label="Config" width="120" align="center"></el-table-column>
			<el-table-column prop="admin" label="Admin" width="120" align="center"></el-table-column>
      </el-table-column>
    </el-table>

  </div>
</template>

<script>
export default {
  data() {
    return {
      pid: "",
      id: "",
      tableData: [
        { menu: "userProfile.a1", mainteneur: 'R', config: 'R', admin: 'R' },
        // { menu: "Tools / Import of data files from phone (*)", mainteneur: '-', config: 'X', admin: 'X' },
        { menu: "userProfile.a2", mainteneur: '-', config: '-', admin: 'X' },
        { menu: "userProfile.a3", mainteneur: 'R', config: 'X', admin: 'X' },
        //{ menu: "userProfile.a4", mainteneur: '-', config: 'X', admin: 'X' },
        { menu: "userProfile.a5", mainteneur: '-', config: '-', admin: 'X' },
        { menu: "userProfile.a6", mainteneur: '-', config: '-', admin: 'X' },
        { menu: "userProfile.a7", mainteneur: '-', config: '-', admin: 'X' },
        //{ menu: "userProfile.a8", mainteneur: '-', config: '-', admin: 'X' },
        { menu: "userProfile.a9", mainteneur: '-', config: 'X', admin: 'X' },
        { menu: "userProfile.a10", mainteneur: '-', config: '-', admin: 'X' },
      ],
    };
  },
  created() {
    let data = sessionStorage.getItem("file");
    if (data) {
      this.pid = JSON.parse(data).id;
    }
    // this.edit = sessionStorage.getItem('edit');
  },

  methods: {
    back() {
      this.$router.go(-1);
    },
    next() {
      let params = {
        pid: this.pid,
        uid: sessionStorage.getItem("id"),
      };
      if (this.id) params.id = this.id;
      this.tableData.forEach((v) => {
        params[v.key] = v.value == true ? 1 : 0;
      });
      this.postRequest("index/set_menus", params, (res) => {
        if (res.data) {
          this.$router.push("/Shortcutkeys?id=" + res.data);
        }
      });
    },
  },
};
</script>

<style scoped>
.search {
  width: 100%;
  height: 46px;
  border: 3px solid #5D80F5;
  border-radius: 1px;
  font-size: 18px;
  text-indent: 20px;
  box-sizing: border-box;
 
}
.search:focus-visible {
  outline: none;
}
.search-btn {
  width: 6%;
  height: 46px;
  line-height: 46px;
  text-align: center;
  font-size: 22px;
  cursor:pointer;
  color: #C3D0FB;
  background: #5D80F5;
}
.flex {
  display: flex;
  justify-content: space-between;
}

.tool-btn {
}

.table {
  margin: 30px 0;
}
</style>
